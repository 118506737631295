import logo from './logo.svg';
import './App.css';
import React from 'react'
import { BrowserRouter, Route,Switch,Routes } from 'react-router-dom';
import Contact from './Components/Contact';
import Home from './Components/Home';
import Service from './Components/Service';
import About from './Components/About';
import Project from './Components/Project';
import NotFountPage from './Components/NotFountPage';
import Login from './Components/Login';
import FAQ from './Components/FAQ';
// import '../src/vendors/styles/core.css'
import '../src/vendors/styles/icon-font.min.css'
import '../src/vendors/styles/style.css'

import Dashboard from './Components/Dashboard';
import Features from './Components/Features';
import PrivateRoute from './Components/PrivateRoute';
// import '../src/plugins/datatables/css/dataTables.bootstrap4.min.css'
// import '../src/plugins/datatables/css/responsive.bootstrap4.min.css'

// import Dashboard from './Components/Dashboard';





function App() {
  
  return (
    <BrowserRouter>
    <div className="App">
    <Routes>
    <Route path='/Login' element={<Login></Login>}/>
    <Route path='/' element={<Home></Home>}/>
    <Route path='/Admin' element={<PrivateRoute Component={Dashboard}/>}>
    {/* <Route path='Admin' element={ <Dashboard />}/> */}
    </Route>
    
    <Route path='/About' element={<About></About>}/>
    <Route path='/Service' element={<Service></Service>}/>
    <Route path='/Contact' element={<Contact></Contact>}/>
    <Route path='/Project' element={<Project></Project>}/>
    <Route path='/Features' element={<Features></Features>}/>
    <Route path='/FAQ' element={<FAQ></FAQ>}/>
    <Route path='*' element={<NotFountPage></NotFountPage>}/>
    </Routes>
    
    </div>
    </BrowserRouter>
  );
}

export default App;
