import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Getstarted from './Getstarted';
const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  return (
    <>
      
       <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
       <Link to="/" className="navbar-brand p-0">
               <h1 className="m-0">DGital</h1>
               
           </Link>
           <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
               <span className="fa fa-bars"></span>
           </button>
           <div className="collapse navbar-collapse" id="navbarCollapse">
               <div className="navbar-nav mx-auto py-0">
               <Link to="/" className="nav-item nav-link">Home</Link>
               <Link to="/About" className="nav-item nav-link">About</Link>
               <Link to="/Service" className="nav-item nav-link">Service</Link>
               <Link to="/Project" className="nav-item nav-link">Project</Link>
                   <div className="nav-item dropdown">
                       <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</Link>
                       <div className="dropdown-menu m-0">
                       <Link to="/login" className="dropdown-item">Admin</Link>
                           <Link to="/features" className="dropdown-item">Features</Link>
                           <Link to="404.html" className="dropdown-item">404 Page</Link>
                       </div>
                   </div>
                   <Link to="/Contact" className="nav-item nav-link">Contact</Link>
                   <Link to="/FAQ" className="nav-item nav-link">FAQ</Link>
               </div>
               <p style={{color:'white',marginTop:'20px'}}><i className="fa fa-phone alt me-3"></i><a style={{color:'white'}} href="tel:8077115322" >Call +91 8077115322</a></p>
               <button onClick={togglePopup} className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block">Get Started</button>
           
               {isOpen && <Getstarted
               
                handleClose={togglePopup}
              />}    </div>
       </nav>

       
  
    </>
  )
}

export default Header
