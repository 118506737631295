import React from 'react'


const Footer = () => {
  return (
    <>
      <div className="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5 px-lg-5">
            <div className="row g-5">
                <div className="col-md-6 col-lg-3">
                    <p className="section-title text-white h5 mb-4">Address<span></span></p>
                    <p><i className="fa fa-map-marker alt me-3"></i>H - 161, BSI Park, Noida 63, Noida 301201</p>
                    

                    <p><i className="fa fa-phone alt me-3"></i><a href="tel:8077115322" style={{color:'white'}}>Call + 91 8077115322</a></p>
                    <p><i className="fa fa-envelope me-3"></i>info@example.com</p>
                    <div className="d-flex pt-2">
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <p className="section-title text-white h5 mb-4">Quick Link<span></span></p>
                    <a className="btn btn-link" href="/About">About Us</a>
                    <a className="btn btn-link" href="/Contact">Contact Us</a>
                    <a className="btn btn-link" href="">Privacy Policy</a>
                    <a className="btn btn-link" href="">Terms & Condition</a>
                    <a className="btn btn-link" href="">Career</a>
                </div>
                <div className="col-md-6 col-lg-3">
                    <p className="section-title text-white h5 mb-4">Gallery<span></span></p>
                    <div className="row g-2">
                        <div className="col-4">
                            <img className="img-fluid" src="img/portfolio-1.jpg" alt="Image"/>
                        </div>
                        <div className="col-4">
                            <img className="img-fluid" src="img/portfolio-2.jpg" alt="Image"/>
                        </div>
                        <div className="col-4">
                            <img className="img-fluid" src="img/portfolio-3.jpg" alt="Image"/>
                        </div>
                        <div className="col-4">
                            <img className="img-fluid" src="img/portfolio-4.jpg" alt="Image"/>
                        </div>
                        <div className="col-4">
                            <img className="img-fluid" src="img/portfolio-5.jpg" alt="Image"/>
                        </div>
                        <div className="col-4">
                            <img className="img-fluid" src="img/portfolio-6.jpg" alt="Image"/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <p className="section-title text-white h5 mb-4">Newsletter<span></span></p>
                    <p style={{textAlign:'justify'}}>Email newsletters can include a weekly round-up of blog posts, case studies regarding your product or service, upcoming company events and webinars, or even a behind-the-scenes look at your company.</p>
                    {/* <div className="position-relative w-100 mt-3">
                        <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Your Email" style={{height: "48px"}}/>
                        <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i className="fa fa-paper-plane text-primary fs-4"></i></button>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="container px-lg-5">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">Dgital Agency Pvt Ltd
                        &copy; <a className="border-bottom" href="#"></a> 2018, All Right Reserved. 
                        
                        
                        Developed By <a className="border-bottom" href="">Dgital Agency</a><br/><br/>
                        {/* Distributed By a <a className="border-bottom" href="" target="_blank">JD</a> */}
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <div className="footer-menu">
                        <a href="/">Home</a>
                            
                            <a href="">Help</a>
                            <a href="/FAQ">FQAs</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   

<ul className='sticky'>

<li>
    <a 
        href="tel:8077115322"
        className="whatsapp_floatt"
        
        rel="noopener noreferrer"
      >
        <i className="fa fa-phone mt-3 ff"></i>
      </a></li>
    

    <li>
    <a 
        href="https://wa.me/+919720610972"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a></li>
    
    
    <li><a  href="#" className="btn btn-lg btn-secondary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a></li>
</ul>
    
    

    </>
  )
}

export default Footer
