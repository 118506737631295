import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const FAQ = () => {
  return (
    <>

<div className="container-xxl bg-white p-0">
   
    


   <div className="container-xxl position-relative p-0">
       <Header/>

       <div className="container-xxl py-5 bg-primary hero-header">
           <div className="container my-5 py-5 px-lg-5">
               <div className="row g-5 py-5">
                   <div className="col-12 text-center">
                       <h1 className="text-white animated slideInDown">FAQ</h1>
                       <hr className="bg-white mx-auto mt-0" style={{width: "90px;"}}/>
                       <nav aria-label="breadcrumb">
                           <ol className="breadcrumb justify-content-center">
                               <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                               <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                               <li className="breadcrumb-item text-white active" aria-current="page">FAQ</li>
                           </ol>
                       </nav>
                   </div>
               </div>
           </div>
       </div>
   </div>
 


      <div className="container justify-content-center" style={{backgroundColor:'white'}}>
        <div className="row g-4" >
          <div>
            <h1 className="py-4">Frequently Asked Questions</h1>
            <p>
              A well-crafted FAQ page should include the most frequently asked
              questions in detail, including clear instructions on how to
              utilize services or products offered by us.
            </p>
          </div>

          <div className="col-md-6" >
            <div  className="service-item" style={{ borderRadius:'10px'}}>
              <h4 className="py-4 " style={{ textAlign: "left" }}>
                What Services Does A Digital Marketing Company Typically Offer?
              </h4>
              <p style={{ textAlign: "justify" }}>
                A digital marketing company typically offers services such as
                search engine optimization (SEO), pay-per-click (PPC)
                advertising, social media marketing, content marketing, email
                marketing, website design and development, and analytics and
                reporting.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div  className="service-item" style={{ borderRadius:'10px'}}>
              <h4 className="py-4" style={{ textAlign: "left" }}>What Is Website Design And Development?</h4>
              <p style={{ textAlign: "justify" }}>
                Website design and development involves creating a website that
                is visually appealing, user-friendly, and optimized for search
                engines. This includes designing the website layout, creating
                content, optimizing for mobile devices, and ensuring that the
                website is secure and meets industry standards.
              </p>
            </div>
          </div>


          <div className="col-md-6" >
            <div  className="service-item" style={{ borderRadius:'10px'}}>
              <h4 className="py-4" style={{ textAlign: "left" }}>
              What Is Analytics And Reporting?
              </h4>
              <p style={{ textAlign: "justify" }}>
              Analytics and reporting involve analyzing data from your website, social media platforms, and other online marketing channels to measure the success of your marketing campaigns. This includes tracking metrics such as website traffic, conversion rates, and engagement rates, and using this data to make data-driven decisions and optimize your marketing strategies.
              </p>
            </div>
          </div>


          <div className="col-md-6" >
            <div  className="service-item" style={{ borderRadius:'10px'}}>
              <h4 className="py-4" style={{ textAlign: "left" }}>
              How Can A Digital Marketing Company Help Improve My Website's Search Engine Rankings?
              </h4>
              <p style={{ textAlign: "justify" }}>
              A digital marketing company can improve your website’s search engine rankings by conducting thorough keyword research to identify the keywords and phrases that your target audience is searching for. They can then optimize your website’s content and structure to make it more search engine-friendly, build high-quality backlinks to your site, and monitor your rankings to ensure that you are ranking for the right keywords and phrases.
              </p>
            </div>
          </div>

          <div className="col-md-6" >
            <div  className="service-item" style={{ borderRadius:'10px'}} >
              <h4 className="py-4" style={{ textAlign: "left" }}>
              How Can A Digital Marketing Company Help Increase My Social Media Engagement?
              </h4>
              <p style={{ textAlign: "justify" }}>
              A digital marketing company can help increase your social media engagement by creating and sharing high-quality, relevant content on your social media channels, engaging with your audience, running social media contests and promotions, and using paid advertising to target your ideal audience.
              </p>
            </div>
          </div>

          <div className="col-md-6" >
            <div  className="service-item" style={{ borderRadius:'10px'}}>
              <h4 className="py-4" style={{ textAlign: "left" }}>
              How Can A Digital Marketing Company Help Me Measure The Success Of My Marketing Campaigns?
              </h4>
              <p style={{ textAlign: "justify" }}>
              A digital marketing company can help you measure the success of your marketing campaigns by tracking and analyzing data from your website, social media channels, and other online marketing channels. This includes tracking metrics such as website traffic, conversion rates, engagement rates, and ROI, and using this data to make data-driven decisions and optimize your marketing strategies.
              </p>
            </div>
          </div>

          <div className="col-md-6" >
            <div  className="service-item" style={{ borderRadius:'10px'}}>
              <h4 className="py-4" style={{ textAlign: "left" }}>
              What Should I Look For When Choosing A Digital Marketing Company?
              </h4>
              <p style={{ textAlign: "justify", borderRadius:'10px',padding:'5px' }}>
              When choosing a digital marketing company, you should look for a company with experience in your industry, a proven track record of success, a range of services that meet your needs, transparent pricing and reporting, and a team of experienced and knowledgeable professionals who can provide personalized advice and support.
              </p>
            </div>
          </div>


          <div className="col-md-6"   >
            <div className="service-item" style={{ borderRadius:'10px'}} >
              <h4 className="py-4" style={{ textAlign: "left"}}>
              How Much Does Digital Marketing Cost?
              </h4>
              <p style={{ textAlign: "justify",padding:'5px'}}>
              The cost of digital marketing services can vary widely depending on the specific services you need, the size and complexity of your website and online presence, and the level of competition in your industry. Some digital marketing companies may charge a flat fee or hourly rate, while others may offer customized pricing based on your specific needs and goals. It’s important to get a clear understanding of pricing and billing before you sign any agreements with a digital marketing company.
              </p>
            </div>
          </div>

          </div>
      </div>


          <Footer></Footer>

</div>



        
    </>
  );
};

export default FAQ;
